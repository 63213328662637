// site layout
.base {
    $base-counter-height: 20px + $narrow-gap; // img height + margin

    padding: $medium-gap $medium-gap $base-counter-height + $medium-gap;
    text-align: center;

    &-column {
        background: $bg-color linear-gradient(to bottom, $bg-color, $fg-color);
        position: relative;
        max-width: $column-width;
        margin: 0 auto;
        padding: $narrow-gap;
        border: $wide-border-size solid $fg-color;
        border-radius: $wide-border-radius;
    }

    &-header {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    }
    &-title-holder {
        flex: 1 3 auto;
        text-align: center;
    }
    &-title {
        display: inline;
        box-shadow: 2 * $narrow-gap 0 0 $fg-color, -$narrow-gap 0 0 $fg-color;
        box-decoration-break: clone;
        background: $fg-color;
    }
    &-accent {
        flex: 0 1 auto;
        margin: $narrow-gap;
    }

    &-home-link {
        display: inline-block;
        margin: $narrow-gap;
    }

    &-footer {
        position: absolute;
        left: 0; right: 0;
        margin-top: $narrow-gap;
        font-size: $meta-font-size;
        line-height: $text-line-height;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    &-footer, &-footer-link {
        color: $meta-color;
    }
    &-copyright {
        line-height: $wide-border-size;
    }
    &-counter {
        margin-top: $narrow-gap;
    }
}
