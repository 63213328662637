// calling card
.card {
    $card-image-width: 240px;

    &-link-body {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: $narrow-gap 0;

        // display items as a column
        @media #{$column-media-query} {
            flex-direction: column;
        }
    }

    &-links {
        flex: 1 1 0px;

        @media #{$column-media-query} {
            width: 100%;
            max-width: $card-image-width + 2 * $narrow-border-size;
        }
    }
    &-link-left {
		justify-content: flex-start;
    }
    &-link-right {
		justify-content: flex-end;
    }
    &-link-left, &-link-right {
    	display: flex;
		align-items: center;
        background: $fg-color;
        margin: $narrow-gap 0;
        padding: $narrow-gap;

        @media #{$column-media-query} {
            justify-content: center;
        }
    }

    &-flair-left {
        margin-left: $narrow-gap;
    }
    &-flair-right {
        margin-right: $narrow-gap;
    }
    &-flair-left, &-flair-right {
        flex: 0 0 auto;
    }

    &-image-holder {
        flex: 0 1 auto;
        margin: 0 $narrow-gap;
        border: $narrow-border-size solid $fg-color;
        background: url("bg.jpg") center $bg-color;

        @media #{$column-media-query} {
            margin: 0;
        }
    }
    &-image {
        width: 100%;
        max-width: $card-image-width;
    }

    &-text-top, &-text-bottom {
        margin: $medium-gap 0;
    }
    &-text-top {
        color: $fg-color;
    }
}
