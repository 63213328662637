// image gallery
.gallery {
    &-text-body {
        margin: $wide-gap 0;
    }

    &-item {
        margin: $medium-gap 0;
    }

    &-figure {
        display: inline-block;
        margin: 0;
    }

    &-image-holder {
        display: inline-block;
        margin-bottom: $narrow-gap;
        border: $narrow-border-size solid $fg-color;
    }
    &-image {
        max-width: 100%;

        @include antialiased;
    }

    &-caption {
        display: inline;
        box-shadow: $narrow-gap 0 0 $fg-color, -$narrow-gap 0 0 $fg-color;
        box-decoration-break: clone;
        background: $fg-color;
        line-height: $text-line-height;
    }
}
