@font-face {
    font-family: "BPreplay";
    src: url("bpreplay-bold-italics.woff") format("woff");
    font-style: italics;
    font-weight: bold;
}

@font-face {
    font-family: "Love";
    src: url("love.woff") format("woff");
    font-style: normal;
    font-weight: normal;
}

body {
    color: $text-color;
    background: url("bg.jpg") center $bg-color;
    font-family: $text-font-family;
    font-size: $text-font-size;
    line-height: 1.0;

    @include pixelated;
}

::selection {
    color: $bg-color;
    background: $fg-color;
}

h1 {
    margin: 0;
    font-family: $heading-font-family;
    font-size: $heading-font-size;
    font-style: italic;
    line-height: $heading-line-height;
}

a { color: $link-color; }
p {
    margin: $narrow-gap 0;
    line-height: $text-line-height;
}
