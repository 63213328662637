// colors
$bg-color: #8b86c9;
$fg-color: #fdb4ff;
$text-color: #56077b;
$link-color: #56077b;
$meta-color: #b26eb3;

// fonts
$heading-font-family: "BPreplay", sans-serif;
$heading-font-size: 24pt;
$heading-line-height: 1.4;
$text-font-family: "Love", sans-serif;
$text-font-size: 14pt;
$text-line-height: 1.2;
$meta-font-size: 0.75em;

// borders
$narrow-border-size: 2px;
$wide-border-size: 20px;
$wide-border-radius: 30px;

// gaps
$narrow-gap: 4px;
$medium-gap: 12px;
$wide-gap: 32px;

// media queries
$column-width: 550px;
$column-media-query: "only screen and (max-width: #{$column-width})";
